.subscribe-banner {
  width: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  position: relative;
  bottom: -36px;

  h2 {
    font-size: 24px;
    line-height: 1.67;
    color: #fff;
    position: relative;
    max-width: 290px;
    margin: 0 auto 8px;

    svg {
      width: 32px;
      position: absolute;
      left: -20px;
      top: -20px;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    position: relative;
    max-width: 350px;
    margin: 0 auto 16px;

    svg {
      position: absolute;
      right: 0;
      top: calc(100% - 12px);
      width: 32px;
    }
  }

  .subscribe-btn {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-transform: uppercase;
    width: 100%;
    height: 56px;
    border-radius: 100px;
    background-color: #f8d34f;
    position: relative;
    right: auto;

    svg {
      fill: #000;
      width: 25px;
      margin-left: 16px;
      animation: shake 800ms infinite ease-out;
      position: absolute;
      top: 50%;
      right: 24px;
    }
  }

  @keyframes shake {
    0% {
      transform: translateX(-5px) translateY(-50%);
    }
    50% {
      transform: translateX(5px) translateY(-50%);
    }

    100% {
      transform: translateX(-5px) translateY(-50%);
    }
  }

  .price-text {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: rgba(255, 255, 255, 0.32);
    margin-top: 8px;
    margin-bottom: 24px;

    span {
      text-transform: uppercase;
    }
  }

  .subscribe-text {
    font-size: 10px;
    line-height: 1.2;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
  }

  &.purchase-banner {
    padding-top: 38px;

    h2 {
      max-width: max-content;
      margin-bottom: 8px;

      svg {
        left: -36px;
        top: -16px;
      }
    }

    p {
      margin-bottom: 24px;
    }

    .subscribe-btn {
      max-width: 240px;
      margin: 0 auto;
    }
  }
}

.subscribe-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    color: rgba(255, 255, 255, 0.32);
    position: relative;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    background: none;
    border-radius: 0;
    padding: 4px;
    margin: 0 3px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -3px;
      display: block;
      height: 12px;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.32);
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}