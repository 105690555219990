.watermark-builder-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #000;

  header {
    width: 100%;
  }

  .btns-container {
    padding: 16px 0 32px;
  }

  .image-container {
    width: 90%;
    height: 100%;
    position: relative;
    margin: 0 auto;
  }

  .hidden-image {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .result-image {
    position: absolute;
    z-index: 10;
  }

  .watermark-holder {
    position: absolute;
    z-index: 11;
  }

  .watermark-tooltip {
    position: absolute;
    z-index: 15;
    top: 50%;
    right: 110%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
      color: #fff;
      background-color: rgba(34, 34, 34, 0.6);
      border-radius: 3px;
      position: relative;
      white-space: nowrap;
      line-height: 1.25;
      padding: 8px;

      &::after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 7px;
        border-color: transparent transparent transparent rgba(34, 34, 34, 0.6);
        position: absolute;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);
      }

      span {
        font-weight: 600;
      }
    }
  }
}