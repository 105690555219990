$window-inner-height: var(--window-inner-height);

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

* {
  box-sizing: border-box;
}

.container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
}

.main-page {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #19161f;
  background-image: linear-gradient(335deg, #3b2c5d, #19161f 30%, #19161f 80%, #153b4c);
  padding-top: 0;
  padding-bottom: 0;
}

.bg-main-page {
  padding-bottom: 0;
}

.main-page-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  width: 88%;
  padding-top: 10px;
  margin: 0 auto;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.67;
    position: relative;
    width: 100%;
    text-align: left;
    margin-bottom: 0;

    img {
      position: absolute;
      left: -50px;
      top: -10px;
      width: 116px;
    }
  }
}

.main-page-text {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  text-align: left;
  margin-bottom: 32px;

  button {
    color: #f8d34f;
    background: none;
    border-bottom: 1px dashed;
  }

  @media all and (max-width: 370px) and (orientation: portrait) {
    font-size: 14px;
  }
}

.btn {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  letter-spacing: 0.32px;
  position: relative;
  text-transform: uppercase;
  width: 240px;
  height: 56px;
  border-radius: 100px;
  margin: 0 auto;

  img {
    height: 48px;
    width: 48px;
    object-fit: contain;
    position: absolute;
    right: -4px;
    top: -10px;
  }

  .image-bottom {
    width: 80px;
    top: auto;
    left: auto;
    right: -20;
    bottom: -12px;
  }

  .image-top {
    top: -24px;
    left: 6px;
  }

  &[disabled] {
    color: rgb(255 255 255 / 45%);
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.btn-black {
  color: #fff;
  background-color: #000;
}

.btn-violet {
  color: #000;
  background-color: #fdabff;
}

.btn-white {
  color: #000;
  background-color: #fff;
}

.btn-blue {
  color: #fff;
  background-color: #4fadf8;
}

.btn-yellow {
  color: #000;
  background-color: #f8d34f;
}

.btn-fixed-bottom {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px;
}

.btn-transparent {
  background: none;
  border: 1px solid #f8d34f;
  color: #f8d34f;
}

.btn-sample {
  color: #f8d34f;
  height: 28px;
  background: none;
  margin-top: 12px;
}

.btn-white-transparent {
  font-size: 12px;
  color: #fff;
  height: 28px;
  background: none;
  margin-top: 16px;
}

.btn-full-width {
  width: 100%;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 8px;
  margin-top: -32px;
}
.btn-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 5px;
  box-sizing: border-box;
  background: none;

  svg {
    opacity: 0.45;
    fill: #fff;
  }
}

.btn-container {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 100;

  &:after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    left: -24px;
    width: calc(100% + 48px);
    height: 160px;
    background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: -24px;
    width: calc(100% + 48px);
    height: 60px;
    background-color: #000;
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    line-height: 1.67;
    margin-bottom: 16px;
  }

  p {
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    color: #fff;
    margin-bottom: 40px;
  }
}

.images-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;

  .image-item {
    position: relative;
  
    &--pending {
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        background-color: rgba(255, 255, 255, 0.45);
      }
    }

    &--valid {
      background: rgb(182, 203, 174);
    }

    &--invalid {
      border: solid 2px #99292d;  

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(153, 41, 45, 0.45);
      }

      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }

    .holder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
        pointer-events: none;
        transition: opacity 240ms;
      }
    }

    .status {
      display: block;
    }

    input {
      position: absolute;
      width: 0;
      height: 0;
      z-index: -20;

      &:checked {
        & + label {
          background-color: #f8d34f;

          svg {
            display: block;
          }
        }
      }
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 8px;
      right: 8px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
      border: solid 1px #fff;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 20;

      svg {
        display: none;
        width: 8px;
        height: 8px;
        fill: #000;
      }
    }
  }
}

.pending-loader-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &.relative {
    position: relative;
  }
}

.pending-loader {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: solid 4px rgba(0, 0, 0, 0.15);
  position: relative;
}

.pending-loader-slider {
  border: solid 4px transparent;
  border-top: solid 4px #f8d34f;
    border-right: solid 4px #f8d34f;
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border-radius: 50%;
  animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.images-grid-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  .btn-upload {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.33;
    color: #999;

    span {
      font-weight: 600;
    }
  }

  .btn-select-delete {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    line-height: 1.33;
    background: none;
  }

  .error-text {
    font-family: 'Poppins', sans-serif;
    font-size: 8px;
    font-weight: 300;
    line-height: 1.5;
    color: #e92f35;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
}

.btns-option-container {
  display: flex;
  align-items: center;
}

.btn-option {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  line-height: 1.33;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background: none;

  img {
    width: 10px;
    margin-right: 6px;
  }

  &:last-child {
    margin-left: 24px;
  }
}

.images-grid-list {
  display: grid;
 grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
}

.image-item,
.btn-more {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  
  &:after {
    content: '';
    width: 100%;
    padding-top: 100%;
    display: block;
  }
}

.btn-more {
  font-family: 'Poppins', sans-serif;
  font-size: 8px;
  font-weight: 500;
  text-align: center;
  color: #000;
  display: flex;
  background-color: #f8d34f;
  position: relative;

  img {
    width: 24px;
    margin-bottom: 8px;
  }
}

.btn-more-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40px;
  margin-top: 24px;
  margin-bottom: 24px;

  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
  }

  button {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: none;

    svg {
      width: 100%;
    }
  }
}

.list-conditions {
  //flex-grow: 1;
  padding: 0 24px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  img {
    width: 24px;
    margin-right: 40px;
  }

  p {
    font-weight: 300;
    color: #fff;
    line-height: 1.25;
  }

  span {
    font-weight: 500;
  }
}

.select-photo {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 260px;

  header {
    margin-bottom: 24px;
  }

  .btn-container {
    .btn {
      color: #19161f;
      background-color: #f8d34f;
    }
  }
}

.upload-photo {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 260px;

  .btn-container {
    .btn {
      color: #19161f;
      background-color: #f8d34f;
    }
  }
}

.loader {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.loader-fixed {
  background: #000;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .timer-animate-block {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;

    svg {
      animation: none;
    }
  }
}

.timer-animate-block {
  margin-left: -24px;
  width: calc(100% + 48px);
  height: 320px;
  position: relative;
  margin-top: 40px;

  svg {
    width: 170%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    animation: spin 60s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(-360deg);
  }
}

.loader .container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10vh;

  .notice-text {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
    text-align: center;
    margin-bottom: 16px;
  }

  .icon-clock {
    width: 48px;
    margin: 0 auto 8px;
  }
}

.loader-content-text-1 {
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 40px;

  span {
    font-style: italic;
  }
}

.loader-content-text-2 {
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 16px;

  span {
    font-weight: bold;
    color: #f8d34f;
  }
}

.loader-slider {
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-bottom: 24px;

  .slick-slider {
    margin: 0 -8px;
  }

  .slick-slide {
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.45);
    margin-right: 8px;
    margin-left: 8px;

    &:after {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: top center;    
    width: 100%;
    height: 100%;
  }
}

.countdown-timer {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  margin-bottom: 16px;
}

.countdown-timer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns-view-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 22px;

  .btn-check-view {
    width: 16px;
    height: 16px;
    display: flex;
    background: none;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 100%;
      height: 100%;
      fill: #999;
    }

    &:first-child {
      box-sizing: border-box;
      padding: 2px;
    }

    &:last-child {
      margin-left: 20px;
    }

    &.active {
      svg {
        fill: #fff;
      }
    }
  }
}

.grid-cols-1 {
  display: flex;
  flex-direction: column;
  // margin-bottom: 72px;
  
  .image-item {
    position: relative;
    border-radius: 48px;
    width: 100%;
    margin-bottom: 16px;
  }

  .holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .btn-download {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
      background-color: #f8d34f;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      transition: opacity 240ms;
    }
  }
}

.grid-cols-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  // margin-bottom: 88px;

  .image-item {
    position: relative;
    border-radius: 28px;
    width: 100%;
  }

  .holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .btn-download {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
      color: #19161f;
      background-color: #f8d34f;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      transition: opacity 240ms;
    }
  }
}

.grid-cols-full {
  margin-bottom: 24px;
}

.previous-avatars-container {
  max-width: 480px;
  width: 88%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 0;
  padding-bottom: 16px;
  margin: 20px auto 0;

  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5;
    color: #f8d34f;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-top: 5px;

    svg {
      display: none;
    }

    .icon-arrow-v2 {
      display: flex;
      width: 24px;
      margin-left: 10px;
    }
  }
}

.previous-avatars {
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    object-fit: cover;
  }
}

.previous-avatar {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  margin: 0;
  margin-right: 10px;

  &.pending {
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      background-color: rgba(255, 255, 255, 0.45);
    }
  }
}

.pack-grid {
  margin-bottom: 32px;

  .images-grid {
    flex-wrap: nowrap;
    overflow: auto;
    box-sizing: border-box;
    width: calc(100% + 48px);
    padding-bottom: 16px;
    padding-left: 24px;
    margin-bottom: 0;
    margin-left: -24px;
  }

  .image-item {
    width: 110px;
    height: 110px;
    border-radius: 28px;
    flex-shrink: 0;
    margin-right: 8px;
  }
}

.pack-grid-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.slider {
  flex-grow: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  

  .slick-slide > div {
    padding: 10px 0;
    margin: 0 12px;
  }

  .slick-list {
    margin: 0 -12px;
  }

  .slide {
      border-radius: 45px;
      position: relative;
      transition: 0.6s;
      will-change: transform;
      padding-top: 100%;

      img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.6s;
          border-radius: 45px;
      }
  }

  .slick-current {
      will-change: transform;
      .slide {
          img {
              transform: rotate(4deg);
          }

          .slide-toolptip {
              display: flex;
          }
      }
  }

  .slick-dots {
      bottom: 4px;

      @media all and (max-width: 450px) {
          display: none;
      }

      li { 
          width: 10px;
          height: 10px;
          border-radius: 6px;
          background-color: #423d58;
          opacity: 1;
          margin: 0 4px;

          button {
              width: 100%;
              height: 100%;
              padding: 0;

              &:before {
                  content: none;
              }
          }
      }

      .slick-active {
          width: 36px;
          background-color: #beb4e8;
      }
  }
}

.slick-slide {
  height: auto; 
}

.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.popup-avatar {
  border-radius: 48px;
  width: 100%;
  margin: 0 auto;
}

.save-button {

  span {
    display: block;
    transition: opacity 60ms;
  }

  svg {
    position: absolute;
    top: -9999px;
    left: -9999px;
    height: 24px;
    opacity: 0;
    transition: opacity 320ms;
  }

  &.pretend-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    border-radius: 50%;
  
    span {
      position: absolute;
      top: -9999px;
      left: -9999px;
      opacity: 0;
    }

    svg {
      width: 14px;
      position: static;
      opacity: 1;
    }
  }
}

.popup-block {
  max-width: 480px;
  width: 100%;
  text-align: center;
  padding: 24px 12px;
  color: #000;
  background-color: #fff;
  border-radius: 48px;

  .popup-title {
    margin-bottom: 16px;
  }

  p {
    font-size: 12px;
    color: #000;
    text-align: left;
    margin-bottom: 16px;
  }

  .notice {
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    background-color: #f8d34f;
    border-radius: 8px;
    padding: 8px;

    svg {
      flex-shrink: 0;
      width: 12px;
      margin-right: 4px;
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  .text-bold {
    font-weight: 600;
  }

  .btn {
    background-color: #f8d34f;
    &:last-child {
      margin-top: 16px;
    }
  }

  &:first-child {
    margin-bottom: 16px;
  }
}

.text-center {
  text-align: center;
}

.text-yellow {
  font-weight: bold;
  color: #ffc50b;
}

.icon-money {
  width: 48px;
  margin-bottom: 40px;
}

.button-close-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 5px;
  box-sizing: border-box;
  background: none;
  position: absolute;
  top: 32px;
  right: 24px;
  z-index: 10000;

  svg {
    opacity: 0.45;
    fill: #fff;
  }
}

.loader-watermark {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.image-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + 48px);
  padding: 24px 24px 21px;
  margin-left: -24px;
  background-size: cover;

  h3 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 8px;

    span {
      color: #f8d34f;
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 16px;

    span {
      font-weight: bold;
    }
  }

  .mb-0 {
    margin-bottom: 0;
  }
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.image-messages-1 {
  background-image: url("/assets/images/bg-one-pics.jpg");
}

.image-messages-2 {
  background-image: url("/assets/images/bg-two-pics.jpg");
}

.image-duplicate-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 8px;
  margin-bottom: 40px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    border-radius: 16px;
  }

  .image-item--duplicate,
  .image-item--original {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  .image-item--duplicate {
    position: relative;
    opacity: 0.8;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 16px;
    }
  }
}

.image-duplicate-text {
  font-weight: 300;
  line-height: 1.5;

  span {
    font-weight: bold;
  }
}

.container-pack {
  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 24px;

    span {
      font-weight: bold;
    }
  }

  .btn-container {
    bottom: 16px;
    background: none;

    &::after,
    &::before {
      content: none;
    }
  }

  .btn {
    color: #19161f;
    background-color: #f8d34f;
  }
}

.originals-pack {
  p {
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 16px;
  }

  img {
    height: 168px;
  }
}

.originals-items {
  display: flex;
  overflow: auto;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding-left: 24px;
  padding-bottom: 16px;
  margin-bottom: 8px;

  .image-item {
    width: auto;
    flex-shrink: 0;
    margin-right: 8px;

    &::after {
      content: none;
    }
  }
}

.results-pack {
  padding-bottom: 88px;

  p {
    font-weight: 600;
    line-height: 1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    img {
      width: 24px;
      margin-top: -8px;
      margin-right: 8px;
    }
  }
}

.results-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  .image-item {
    position: relative;
  }

  .holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.popup-images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  max-width: 256px;
  margin: 0 auto 40px;

  img {
    width: 80px;
    height: 80px;
    border-radius: 16px;
    object-fit: cover;
    object-position: top center;
  }

  .circle {
    border-radius: 50%;
  }
}

.popup-overlay-same {
  justify-content: flex-start;
  overflow: auto;
  padding: 20px 0;

  .popup {
    flex-grow: 1;
  }
}

.popup-same {
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.67;
    text-align: center;
    margin-bottom: 16px;

    span {
      color: #ffc50b;
    }
  }

  p {
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 40px;

    .text-bold {
      font-weight: bold;
    }

    .text-color {
      color: #ffc50b;
    }
  }
}

.icon-arrow-v2 {
  display: none;
}

.watermark-builder-modal {
  .save-button {
    color: #19161f;
    background-color: #f8d34f;
  }
}

.info-pro {
  max-width: 480px;
  width: 88%;
  margin: 5px auto 0;

  p {
    font-size: 10px;
    line-height: 1.6;
    text-align: center;
    color: #a094bb;
  }

  .btns-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    button {
      font-size: 10px;
      font-weight: bold;
      line-height: 1.6;
      color: rgba(160, 148, 187, 0.7);
      background: none;
      position: relative;
      margin: 0 8px;

      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 70%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -8px;
        background-color: rgba(160, 148, 187, 0.7);
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }
}

.info-pro-price {
  font-size: 10px;
  line-height: 2.4;
  letter-spacing: 0.2px;
  text-align: center;
  color: #a094bb;
}

.info-pro-text {
  position: relative;
  margin-bottom: 28px;
  margin-left: 25px;
  margin-top: 25px;

  p {
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: #f8d34f;
  }

  svg {
    width: 36px;
    position: absolute;
    left: -30px;
    top: -15px;
  }
}

.puzzles-container {
  max-width: 480px;
  margin: 0 auto;
  padding-top: 60px;

  @media all and (max-height: 780px) and (min-width: 500px) and (orientation: portrait) {
    .puzzles-row-3 {
      display: none;
    }
  }
}

.puzzles-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 0 20px;
  margin-bottom: 10px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
  }

  .puzzle-item {
    display: flex;
    position: relative;
    padding-top: 100%;
  }
}

.puzzles-row-1 {
  .puzzle-item {
    align-items: flex-end;
  }

  .puzzle-item:nth-child(1) {
    img {
      border-radius: 50%;
    }

    img:nth-child(1) {
      animation: scale-3 12s linear infinite;
      transform: scale(0);
    }

    img:nth-child(2) {
      animation: scale-2 12s linear infinite;
      transform: scale(0);
    }

    img:nth-child(3) {
      animation: scale-1 12s linear infinite;
    }
  }

  .puzzle-item:nth-child(2) {
    img {
      border-radius: 20px;
      height: 100%;
    }

    img:nth-child(1) {
      animation: fade-3 12s linear infinite;
      opacity: 0;
    }

    img:nth-child(2) {
      animation: fade-2 12s linear infinite;
      opacity: 0;
    }

    img:nth-child(3) {
      animation: fade-1 12s linear infinite;
    }
  }

  .puzzle-item:nth-child(3) {
    img {
      border-radius: 50% 50% 0 50%;
    }

    img:nth-child(1) {
      animation: rotate-3 12s linear infinite;
      transform: rotate(-90deg);
      opacity: 0;
    }

    img:nth-child(2) {
      animation: rotate-2 12s linear infinite;
      transform: rotate(-90deg);
      opacity: 0;
    }

    img:nth-child(3) {
      animation: rotate-1 12s linear infinite;
    }
  }

  img {
    top: auto;
    bottom: 0;
  }
}

.puzzles-row-2 {
  .puzzle-item:first-child {
    img {
      height: 100%;
      object-fit: cover;
      object-position: right top;
      left: -20px;
      width: calc(100% + 20px);
    }
  }

  .puzzle-item:nth-child(1) {
    img {
      border-radius: 0 20px 20px 0;
    }

    img:nth-child(1) {
      animation: fade-3 12s linear infinite;
      opacity: 0;
    }

    img:nth-child(2) {
      animation: fade-2 12s linear infinite;
      opacity: 0;
    }

    img:nth-child(3) {
      animation: fade-1 12s linear infinite;
    }
  }

  .puzzle-item:nth-child(2) {
    img {
      border-radius: 0 50% 50% 50%;
    }

    img:nth-child(1) {
      animation: rotate-3 12s linear infinite;
      transform: rotate(-90deg);
      opacity: 0;
    }

    img:nth-child(2) {
      animation: rotate-2 12s linear infinite;
      transform: rotate(-90deg);
      opacity: 0;
    }

    img:nth-child(3) {
      animation: rotate-1 12s linear infinite;
    }
  }

  .puzzle-item:nth-child(3) {
    img {
      border-radius: 0 20px 20px 20px;
    }

    img:nth-child(1) {
      animation: rotate-3-3 12s linear infinite;
      transform: rotate(-180deg);
      opacity: 0;
    }

    img:nth-child(2) {
      animation: rotate-2-3 12s linear infinite;
      transform: rotate(-180deg);
      opacity: 0;
    }

    img:nth-child(3) {
      animation: rotate-1-3 12s linear infinite;
    }
  }
}

.puzzles-row-3 {
  margin-bottom: 0;

  .puzzle-item {
    align-items: flex-start;
  }

  .puzzle-item:last-child {
    padding-top: 118%;
    img {
      height: 100%;
      object-fit: cover;
      object-position: right top;
      width: calc(100% + 20px);
    }
  }

  .puzzle-item:nth-child(1) {
    img {
      border-radius: 50% 50% 50% 0;
    }

    img:nth-child(1) {
      animation: rotate-3 12s linear infinite;
      transform: rotate(-90deg);
      opacity: 0;
    }

    img:nth-child(2) {
      animation: rotate-2 12s linear infinite;
      transform: rotate(-90deg);
      opacity: 0;
    }

    img:nth-child(3) {
      animation: rotate-1 12s linear infinite;
    }
  }

  .puzzle-item:nth-child(2) {
    img {
      border-radius: 20px 20px 0 20px;
    }

    img:nth-child(1) {
      animation: rotate-3-2 12s linear infinite;
      transform: rotate(-90deg);
      opacity: 0;
    }

    img:nth-child(2) {
      animation: rotate-2-2 12s linear infinite;
      transform: rotate(-90deg);
      opacity: 0;
    }

    img:nth-child(3) {
      animation: rotate-1-2 12s linear infinite;
    }
  }

  .puzzle-item:nth-child(3) {
    img {
      border-radius: 20px 0 0 20px;
    }

    img:nth-child(1) {
      animation: fade-3 12s linear infinite;
      opacity: 0;
    }

    img:nth-child(2) {
      animation: fade-2 12s linear infinite;
      opacity: 0;
    }

    img:nth-child(3) {
      animation: fade-1 12s linear infinite;
    }
  }
}

.subscription {
  &.select-photo {
    padding-bottom: 300px;
  }

  .btn-container {
    &::after {
      height: 60px;
    }
  }
}

.btns-container-flex {
  display: flex;
  flex-direction: column;
  margin-bottom: -40px;
  position: relative;
  z-index: 10;

  .btn {
    width: 300px;
  }

  .info-pro-text {
    margin-bottom: 16px;
    margin-top: 8px;
    margin-left: 10px;

    p {
      line-height: 1.25;
    }
  }
}

@keyframes fade-1 {
  0% {
    opacity: 1;
    z-index: 10;
  }

  31% {
    opacity: 1;
    z-index: 5;
  }

  33% {
    opacity: 0;
    z-index: 8;
  }

  98% {
    opacity: 0;
    z-index: 8;
  }

  100% {
    opacity: 1;
    z-index: 10;
  }
}

@keyframes fade-2 {
  0% {
    opacity: 0;
    z-index: -10;
  }

  31% {
    opacity: 0;
  }

  33% {
    opacity: 1;
    z-index: 10;
  }

  64% {
    opacity: 1;
    z-index: 5;
  }

  66% {
    opacity: 0;
    z-index: 0;
  }

  90% {
    opacity: 0;
    z-index: -10;
  }
}

@keyframes fade-3 {
  0% {
    opacity: 0;
    z-index: 0;
  }

  64% {
    opacity: 0;
  }

  66% {
    opacity: 1;
    z-index: 10;
  }

  97% {
    opacity: 1;
    z-index: 5;
  }

  99% {
    opacity: 0;
    z-index: 0;
  }
}

@keyframes scale-1 {
  0% {
    transform: scale(1);
    z-index: 10;
  }

  31% {
    transform: scale(1);
    z-index: 5;
  }

  33% {
    transform: scale(0);
    z-index: 8;
  }

  98% {
    transform: scale(0);
    z-index: 8;
  }

  100% {
    transform: scale(1);
    z-index: 10;
  }
}

@keyframes scale-2 {
  0% {
    transform: scale(0);
    z-index: -10;
  }

  31% {
    transform: scale(0);
  }

  33% {
    transform: scale(1);
    z-index: 10;
  }

  64% {
    transform: scale(1);
    z-index: 5;
  }

  66% {
    transform: scale(0);
    z-index: 0;
  }

  90% {
    transform: scale(0);
    z-index: -10;
  }
}

@keyframes scale-3 {
  0% {
    transform: scale(0);
    z-index: 0;
  }

  64% {
    transform: scale(0);
  }

  66% {
    transform: scale(1);
    z-index: 10;
  }

  97% {
    transform: scale(1);
    z-index: 5;
  }

  99% {
    transform: scale(0);
    z-index: 0;
  }
}

@keyframes rotate-1 {
  0% {
    transform: rotate(0);
    z-index: 10;
    opacity: 1;
  }

  31% {
    transform: rotate(0);
    z-index: 5;
    opacity: 1;
  }

  32% {
    opacity: 0;
  }

  33% {
    transform: rotate(90deg);
    z-index: 8;
  }

  35% {
    transform: rotate(-90deg);
    z-index: 8;
  }

  98% {
    transform: rotate(-90deg);
    z-index: 8;
    opacity: 0;
  }

  99% {
    opacity: 1;
  }

  100% {
    transform: rotate(0);
    z-index: 10;
  }
}

@keyframes rotate-2 {
  0% {
    transform: rotate(-90deg);
    z-index: -10;
    opacity: 0;
  }

  31% {
    transform: rotate(-90deg);
    opacity: 0;
  }

  32% {
    opacity: 1;
  }

  33% {
    transform: rotate(0);
    z-index: 10;
    opacity: 1;
  }

  64% {
    transform: rotate(0);
    z-index: 5;
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  66% {
    transform: rotate(90deg);
    z-index: 0;
    opacity: 0;
  }

  90% {
    transform: rotate(-90deg);
    z-index: -10;
    opacity: 0;
  }
}

@keyframes rotate-3 {
  0% {
    transform: rotate(-90deg);
    z-index: 0;
    opacity: 0;
  }

  64% {
    transform: rotate(-90deg);
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  66% {
    transform: rotate(0);
    z-index: 10;
    opacity: 1;
  }

  97% {
    transform: rotate(0);
    z-index: 5;
    opacity: 1;
  }

  98% {
    opacity: 0;
  }

  99% {
    transform: rotate(90deg);
    z-index: 0;
    opacity: 0;
  }
}

@keyframes rotate-1-2 {
  0% {
    transform: rotate(0);
    z-index: 10;
    opacity: 1;
  }

  32% {
    transform: rotate(0);
    z-index: 5;
    opacity: 1;
  }

  33% {
    opacity: 0;
  }

  34% {
    transform: rotate(90deg);
    z-index: 8;
  }

  36% {
    transform: rotate(-90deg);
    z-index: 8;
  }

  99% {
    transform: rotate(-90deg);
    z-index: 8;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate-2-2 {
  0% {
    transform: rotate(-90deg);
    z-index: -10;
    opacity: 0;
  }

  30% {
    transform: rotate(-90deg);
    opacity: 0;
  }

  31% {
    opacity: 1;
  }

  32% {
    transform: rotate(0);
    z-index: 10;
    opacity: 1;
  }

  63% {
    transform: rotate(0);
    z-index: 5;
    opacity: 1;
  }

  64% {
    opacity: 0;
  }

  65% {
    transform: rotate(90deg);
    z-index: 0;
    opacity: 0;
  }

  89% {
    transform: rotate(-90deg);
    z-index: -10;
    opacity: 0;
  }
}

@keyframes rotate-3-2 {
  0% {
    transform: rotate(-90deg);
    z-index: 0;
    opacity: 0;
  }

  63% {
    transform: rotate(-90deg);
    opacity: 0;
  }

  64% {
    opacity: 1;
  }

  65% {
    transform: rotate(0);
    z-index: 10;
    opacity: 1;
  }

  96% {
    transform: rotate(0);
    z-index: 5;
    opacity: 1;
  }

  97% {
    opacity: 0;
  }

  98% {
    transform: rotate(90deg);
    z-index: 0;
    opacity: 0;
  }
}

@keyframes rotate-1-3 {
  0% {
    transform: rotate(0);
    z-index: 10;
    opacity: 1;
  }

  31% {
    transform: rotate(0);
    z-index: 5;
    opacity: 1;
  }

  32% {
    opacity: 0;
  }

  33% {
    transform: rotate(180deg);
    z-index: 8;
  }

  35% {
    transform: rotate(-180deg);
    z-index: 8;
  }

  98% {
    transform: rotate(-180deg);
    z-index: 8;
    opacity: 0;
  }

  99% {
    opacity: 1;
  }

  100% {
    transform: rotate(0);
    z-index: 10;
  }
}

@keyframes rotate-2-3 {
  0% {
    transform: rotate(-180deg);
    z-index: -10;
    opacity: 0;
  }

  31% {
    opacity: 0;
  }

  32% {
    opacity: 1;
    transform: rotate(-180deg);
  }

  33% {
    z-index: 10;
    opacity: 1;
  }

  34% {
    transform: rotate(0);
  }

  64% {
    transform: rotate(0);
    z-index: 5;
    opacity: 1;
  }

  65% {
    opacity: 0;
  }

  66% {
    transform: rotate(180deg);
    z-index: 0;
    opacity: 0;
  }

  90% {
    transform: rotate(-180deg);
    z-index: -10;
    opacity: 0;
  }
}

@keyframes rotate-3-3 {
  0% {
    transform: rotate(-180deg);
    z-index: 0;
    opacity: 0;
  }

  64% {
    transform: rotate(-180deg);
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  66% {
    z-index: 10;
    opacity: 1;
  }

  67% {
    transform: rotate(0);
  }

  97% {
    transform: rotate(0);
    z-index: 5;
    opacity: 1;
  }

  98% {
    opacity: 0;
  }

  99% {
    transform: rotate(180deg);
    z-index: 0;
    opacity: 0;
  }
}


@media all and (max-height: 730px) and (orientation: portrait) {
  .main-page {
    padding-top: 0;
    padding-bottom: 16px;
  }

  .loader-content-text {
    margin-bottom: 16px;
  }

  .countdown-timer {
    margin-bottom: 16px;
  }

  .timer-animate-block {
    height: 200px;
    margin-top: 16px;
  }
}

@media all and (max-height: 700px) and (orientation: portrait) {
  .loader-content {
    padding-bottom: 24px;
  }

  .loader-content-text-1 {
    margin-bottom: 16px;
  }

  .loader-content .icon-clock {
    width: 32px;
  }

  .countdown-timer {
    font-size: 36px;
  }
}

@media all and (max-height: 650px) and (orientation: portrait) {
  .main-page-block {
    padding-top: 10px;
  }

  .main-page-block h1 {
    line-height: 1.25;
    margin-bottom: 8px;
  }

  .main-page-text {
    margin-bottom: 24px;
  }

  .puzzles-row-3 {
    display: none;
  }

  .puzzles-row-2 {
    margin-bottom: 0;
  }
}

@media all and (max-height: 720px) and (orientation: portrait) {
  .puzzles-container {
    padding-top: 16px;
  }
}

@media all and (max-height: 600px) and (orientation: portrait) {
  .loader-content .icon-clock {
    width: 24px;
  }

  .loader-content .notice-text {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .loader-content-text-1,
  .loader-content-text-2 {
    font-size: 14px;
  }

  .loader-content-text-1 {
    margin-bottom: 16px;
  }

  .icon-clock {
    display: none;
  }

  .countdown-timer {
    font-size: 32px;
    margin-bottom: 8px;
  }

  .loader-slider .slick-slide {
    border-radius: 24px;
  }

  .popup-images {
    margin-bottom: 24px;
  }

  .popup-same h2 {
    font-size: 22px;
    line-height: 1.25;
  }

  .popup-same p {
    margin-bottom: 24px;
  }
}

@media all and (max-height: 540px) and (orientation: portrait) {
  .popup-images img {
    width: 68px;
    height: 68px;
  }

  .popup-images {
    max-width: 220px;
    margin-bottom: 16px;
  }

  .popup-same h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .popup-same p {
    margin-bottom: 16px;
    font-size: 14px;
  }
}

@media all and (max-height: 500px) and (orientation: portrait) {
  .timer-animate-block {
    height: 120px;
  }
}
