// html, body, .root, main {
//     height: 100%;
// }
// .root > div {
//     height: 100%;
//     & > div {
//         height: 100%;
//     }
// }

@font-face {
   font-family: 'HelveticaNeue';
   font-weight: 300;
   src: url('/assets/fonts/HelveticaNeueCyr-Light.woff2') format('woff2');
   font-display: block;
}

@font-face {
    font-family: 'HelveticaNeue';
    font-weight: 500;
    src: url('/assets/fonts/HelveticaNeueCyr-Medium.woff2') format('woff2');
    font-display: block;
}

 @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/Poppins-ThinItalic.woff2') format('woff2');
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url('/assets/fonts/Poppins-Bold.woff2') format('woff2');
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url('/assets/fonts/Poppins-SemiBold.woff2') format('woff2');
    font-display: block;
}

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('/assets/fonts/Poppins-Regular.woff2') format('woff2');
    font-display: block;
}


@keyframes fadein {
    from { opacity: 0 }
    to { opacity: 1 }
}

[hidden] {
    display: none !important;
}

.file-field-hidden {
    display: none;
}

body {
    font-family: "HelveticaNeue", sans-serif;
    font-size: 16px;
    color: #fff;
    background-color: #000;
    overflow-x: hidden;
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

p {
    line-height: normal;
}

button {
    cursor: pointer;
    position: relative;
    padding: 0;
    input {
        opacity: 0;
        height: 0 !important;
        position: absolute;
        padding: 0 !important;
        margin: 0 !important;
    }
    &[disabled] {
        color: #999;
        background-color: #f3f3f3;
    }

    &:active {
        opacity: 0.6;
    }
}

a {
    text-decoration: none;
}

input {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

button {
    border: none;
}